export default `
  type Query {
    renderInfo: RenderInfo!
  }

  type Metadata {
    orderId: String
    token: String
    grid: String
    use: Boolean
    isVisible: Boolean
    titleIsVisible: Boolean
  }

  type MainEntity {
    id: ID
    metadata: Metadata
  }

  type PageEntity {
    id: ID
  }
  type FragmentEntity {
    id: ID
  }
  
  type RenderInfo {
    type: String!
    rootId: String!
    mainEntity: MainEntity
    pageEntity: PageEntity
    fragmentEntity: FragmentEntity
  }
`;
